import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import AboutUsPage from "../pages/AboutUs";
import LoginPage from "../pages/Dashboard/Login";
import BlogPublisherPage from "../pages/Dashboard/BlogPublisher";
import OraProjectPage from "../pages/OraProject";
import BlogPage from "../pages/Blog";
import BlogArticlesPage from "../pages/Blog/BlogArticles";
import ListBlogEditPage from "../pages/Dashboard/ListBlogEdit";
import UploadImagesPage from "../pages/Dashboard/UploadImages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "/dashboard",
    element: <LoginPage />,
  },
  {
    path: "/dashboard/blog-publisher",
    element: <BlogPublisherPage />,
  },
  {
    path: "/dashboard/upload-images",
    element: <UploadImagesPage />,
  },
  {
    path: "/dashboard/list-edit",
    element: <ListBlogEditPage />,
  },
  {
    path: "/ora-by-casa-tua",
    element: <OraProjectPage />,
  },
  {
    path: "/blog",
    element: <BlogPage />,
  },
  {
    path: "/blog/:pageNumber",
    element: <BlogArticlesPage />,
  },
]);

export default router;
