import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import ThiagoSimpleLogo from "../../assets/logos/simple-logo.svg";
import ThiagoLogo from "../../assets/logos/logo-text.svg";
import ThiagoLogoWhite from "../../assets/logos/logo-text-white.svg";
import BlogArticles from "../../components/BlogArticle";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListPosts from "../../api/listPosts";
import useScrollToTop from "../../hooks/useScrollToTop";

const BlogPage: React.FC = () => {
  useScrollToTop();

  const [articles, setArticles] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const postsBlog = async () => {
      const blogPosts = await ListPosts();
      switch (blogPosts.status) {
        case 200:
          const listBlogArray = blogPosts.res.reverse();
          setArticles(listBlogArray);

          break;
        default:
          console.log("error");
      }
    };

    postsBlog();
  }, []);

  return (
    <LayoutDefault className="blog-page" hasBusinessCard>
      <div className="summary-posts">
        {articles.map((article: any, index: number) => (
          <BlogArticles
            key={index}
            article={article}
            onClick={() => navigate(`/blog/${index + 1}`)}
          />
        ))}
      </div>
    </LayoutDefault>
  );
};

export default BlogPage;
