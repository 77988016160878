import fetchApi from "./fetchApi";

const DeleteImageById = async (token: string, id: string) => {
  return await fetchApi({
    service: "driveFiles",
    method: "DELETE",
    endPoint: `/${id}`,
    token,
  });
};

export default DeleteImageById;
