import React from "react";
import "./styles.scss";
import { IPost } from "../../types/posts";
import { formatDate } from "../../utils/formatDate";

interface BlogArticlesProps {
  article: IPost;
  onClick: (a: any) => void;
}

const BlogArticles: React.FC<BlogArticlesProps> = (props) => {
  const { article, onClick } = props;

  const getImageSrc = () => {
    const image = article.images[0];
    return image instanceof File ? URL.createObjectURL(image) : image;
  };

  return (
    <div id="blog-articles-container">
      <div className="article-details">
        <h2 onClick={onClick}>{article.blog_title}</h2>
        <p>{formatDate(article.created_at)}</p>
      </div>
      <img src={getImageSrc()} alt="article figure" onClick={onClick} />
    </div>
  );
};

export default BlogArticles;
