import React, { useEffect, useState } from "react";
import "./styles.scss";
import DashboardLayout from "../../../components/Wrappers/DashboardLayout";
import ImageUploader from "../../../components/Inputs/ImageUpload";
import { IPost } from "../../../types/posts";
import Button from "../../../components/Buttons/Button";
import ListPhotosGallery from "../../../api/getListPhotosGallery";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../components/LoadingPage";
import { useModal } from "../../../hooks/useModal";
import Toast from "../../../components/Toast";
import PostUploadImages from "../../../api/postUploadImages";
import { IToast } from "../../../types/IToast";
import CloseIcon from "../../../assets/icons/close-circle-icon-blue.svg";
import DeleteImageById from "../../../api/deleteImages";

type DriveImage = {
  id: string;
  image: string;
  created_at: Date;
};

const UploadImagesPage: React.FC = () => {
  const { setModalContent, setOpenUseModal } = useModal();
  const [blogPost, setBlogPost] = useState<IPost>({
    blog_title: "",
    themes: [],
    blog_text: "",
    images: [],
    video_link: "",
  });
  const [photosGallery, setPhotosGallery] = useState<DriveImage[] | []>();
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("tc_access_token");

    if (!auth) {
      navigate("/dashboard");
    } else {
      setToken(auth);
    }
  }, []);

  useEffect(() => {
    getAllPhotos();
  }, []);

  const getAllPhotos = async () => {
    const response = await ListPhotosGallery();
    console.log(response);

    setPhotosGallery(response.res);
  };

  const [showGallery, setShowGallery] = useState(true);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: "info",
    title: "",
    description: "",
  });

  const [isVisibleToast, setIsVisibleToast] = useState(false);

  const imagesCallback = (a: any) => {
    setBlogPost({ ...blogPost, images: a });
  };

  const showGalleryHandler = () => {
    setShowGallery(true);
  };

  const uploadImagesHandler = () => {
    setShowGallery(false);
  };

  const uploadImagesRequest = async () => {
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);

    const uploadPromises = blogPost.images.map(async (image) => {
      if (image instanceof File) {
        const data = new FormData();
        data.append("image", image, image.name);

        try {
          const response = await PostUploadImages(token, data);

          switch (response.status) {
            case 201:
              setToastConfig({
                description: `Image uploaded successfully`,
                title: "Success!",
                type: "success",
              });
              setIsVisibleToast(true);
              setShowGallery(true);
              getAllPhotos();
              setTimeout(() => {
                setIsVisibleToast(false);
              }, 3000);
              break;

            case 401:
              setToastConfig({
                type: "warning",
                title: "Access Denied",
                description: "You will be redirected",
              });
              setIsVisibleToast(true);
              setTimeout(() => {
                setIsVisibleToast(false);
                setShowGallery(true);
              }, 3000);
              break;

            default:
              setToastConfig({
                type: "info",
                title: "We have a problem.",
                description: `Contact the developer team. Code: ${response.status}`,
              });
              setIsVisibleToast(true);
              setTimeout(() => setIsVisibleToast(false), 3000);
              break;
          }
        } catch (error) {
          console.log(error);
          setToastConfig({
            type: "info",
            title: "We have a problem.",
            description: `Contact the developer team.`,
          });
          setIsVisibleToast(true);
          setTimeout(() => {
            setIsVisibleToast(false);
          }, 3000);
        }
      }
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.log("One or more uploads failed:", error);
    }

    setBlogPost({
      blog_title: "",
      themes: [],
      blog_text: "",
      images: [],
      video_link: "",
    });

    setOpenUseModal(false);
  };

  const deleteImageRequest = async (id: string) => {
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);
    try {
      const response = await DeleteImageById(token, id);

      switch (response.status) {
        case 200:
          setToastConfig({
            description: `Image deleted	 successfully`,
            title: "Success!",
            type: "success",
          });
          setIsVisibleToast(true);

          getAllPhotos();
          setTimeout(() => {
            setIsVisibleToast(false);
          }, 3000);
          break;

        case 401:
          setToastConfig({
            type: "warning",
            title: "Access Denied",
            description: "You will be redirected",
          });
          setIsVisibleToast(true);
          setTimeout(() => {
            setIsVisibleToast(false);
            setShowGallery(true);
          }, 3000);
          break;

        default:
          setToastConfig({
            type: "info",
            title: "We have a problem.",
            description: `Contact the developer team. Code: ${response.status}`,
          });
          setIsVisibleToast(true);
          setTimeout(() => setIsVisibleToast(false), 3000);
          break;
      }
    } catch (error) {
      console.log(error);
      setToastConfig({
        type: "info",
        title: "We have a problem.",
        description: `Contact the developer team.`,
      });
      setIsVisibleToast(true);
      setTimeout(() => {
        setIsVisibleToast(false);
      }, 3000);
    }

    setModalContent("");
    setOpenUseModal(false);
  };

  const openModalToDeleteImage = (id: string) => {
    const confirmDeletePostModalContent = () => {
      return (
        <section className="confirm-delete-post-modal-content">
          <div className="confirm-delete-post-modal-content__text">
            <h1>Attention!</h1>
            <p>Are you sure you want to delete this image?</p>
            <p>This action cannot be undone.</p>
          </div>
          <div className="confirm-delete-post-modal-content__buttons-container margin-top-24">
            <Button
              buttonStyle="primary"
              onClick={() => {
                setOpenUseModal(false);
                setModalContent("");
              }}
            >
              Cancel
            </Button>
            <Button
              buttonStyle="tertiary"
              onClick={() => deleteImageRequest(id)}
            >
              Confirm delete
            </Button>
          </div>
        </section>
      );
    };

    setModalContent(confirmDeletePostModalContent());
    setOpenUseModal(true);
  };

  const copyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setToastConfig({
        description: "Copied successfully",
        title: "Success!",
        type: "success",
      });
      setIsVisibleToast(true);

      setTimeout(() => {
        setIsVisibleToast(false);
      }, 4000);
    } catch (err) {
      console.error(err);

      setToastConfig({
        description: "Error when copying",
        title: "Oops...",
        type: "error",
      });
      setIsVisibleToast(true);

      setTimeout(() => {
        setIsVisibleToast(false);
      }, 4000);
    }
  };

  return (
    <DashboardLayout className="upload-images">
      <nav className="upload-images__navbar">
        {showGallery ? (
          <Button buttonStyle="primary" onClick={uploadImagesHandler}>
            Upload
          </Button>
        ) : (
          <Button buttonStyle="primary" onClick={showGalleryHandler}>
            Gallery
          </Button>
        )}
      </nav>
      {showGallery ? (
        <section className="upload-images__gallery margin-vert-24">
          <div className="gallery__images-container">
            {photosGallery?.map((img, index) => (
              <div key={index} className="images-container__img">
                <img
                  src={CloseIcon}
                  alt="delete icon"
                  onClick={() => openModalToDeleteImage(img.id)}
                />
                <img src={img.image} alt="gallery item" />
                <button
                  className="button-copy button"
                  onClick={() => copyClipBoard(img.image)}
                >
                  Copy URL
                </button>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section className="upload-images__upload margin-vert-24">
          <ImageUploader
            buttonText={`add${
              blogPost.images.length >= 1 ? " more images" : " image"
            }`}
            imagesCallback={imagesCallback}
            className="padding-hor-24"
          />
          {blogPost.images.length > 0 && (
            <Button buttonStyle="primary" onClick={uploadImagesRequest}>
              Upload images
            </Button>
          )}
        </section>
      )}
      <Toast
        type={toastConfig.type}
        isVisible={isVisibleToast}
        setIsVisible={setIsVisibleToast}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </DashboardLayout>
  );
};

export default UploadImagesPage;
