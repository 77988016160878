import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/reset.scss";
import "./assets/scss/var.scss";
import "./assets/scss/global.scss";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import AppProvider from "./hooks";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);
