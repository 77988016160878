import { IContact } from "../types/IContact";
import fetchApi from "./fetchApi";

const SendEmailContact = async (templateData: IContact) => {
  return await fetchApi({
    service: "sendContactEmail",
    method: "POST",
    endPoint: ``,
    data: templateData,
  });
};

export default SendEmailContact;
