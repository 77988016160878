import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import DashboardLayout from "../../../components/Wrappers/DashboardLayout";
import TextArea from "../../../components/TextArea";
import ImageUploader from "../../../components/Inputs/ImageUpload";
import Button from "../../../components/Buttons/Button";
import CloseIcon from "../../../assets/icons/close-circle-icon-blue.svg";
import Toast from "../../../components/Toast";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useNavigate } from "react-router-dom";
import InputText from "../../../components/Inputs/InputText";
import { IPost } from "../../../types/posts";
import { IToast } from "../../../types/IToast";
import { useModal } from "../../../hooks/useModal";
import LoadingPage from "../../../components/LoadingPage";
import CreatePost from "../../../api/createPost";
// import JoditEditor from "jodit-react";

const BlogPublisher: React.FC = () => {
  useScrollToTop();
  const { setModalContent, setOpenUseModal } = useModal();

  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [themeInput, setThemeInput] = useState("");
  const [blogPost, setBlogPost] = useState<IPost>({
    blog_title: "",
    themes: [],
    blog_text: "",
    images: [],
    video_link: "",
  });
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: "info",
    title: "",
    description: "",
  });

  const [isVisibleToast, setIsVisibleToast] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    const auth = localStorage.getItem("tc_access_token");

    if (!auth) {
      navigate("/dashboard");
    } else {
      setToken(auth);
    }
  }, []);

  const imagesCallback = (a: any) => {
    setBlogPost({ ...blogPost, images: a });
  };

  const addTheme = () => {
    if (themeInput.trim() !== "") {
      setBlogPost((prevState) => ({
        ...prevState,
        themes: [...prevState.themes, themeInput.trim()],
      }));
      setThemeInput("");
    }
  };

  const blogPublisherHandler = async () => {
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);
    const data = new FormData();

    data.append("blog_title", blogPost.blog_title);
    data.append("blog_text", blogPost.blog_text);
    data.append("video_link", blogPost.video_link || "");

    blogPost.images.forEach((image) => {
      if (image instanceof File) {
        data.append("images", image, image.name);
      }
    });

    if (blogPost.themes.length > 0) {
      data.append("themes", JSON.stringify(blogPost.themes));
    }

    // if (blogPost.themes.length > 0) {
    //   blogPost.themes.map((theme) => data.append("themes", theme));
    // }

    try {
      const response = await CreatePost(token, data);

      switch (response.status) {
        case 201:
          setToastConfig({
            description: "Article published successfully",
            title: "Success!",
            type: "success",
          });

          setIsVisibleToast(true);
          setBlogPost({
            blog_title: "",
            themes: [],
            blog_text: "",
            images: [],
            video_link: "",
          });
          navigate("/dashboard/list-edit");

          setTimeout(() => {
            setIsVisibleToast(false);
          }, 4000);

          break;

        case 401:
          setToastConfig({
            type: "warning",
            title: "Access Denied",
            description: "You will be redirected",
          });

          setIsVisibleToast(true);
          setTimeout(() => {
            setIsVisibleToast(false);
            navigate("/dashboard");
          }, 3000);

          break;
        default:
          setToastConfig({
            type: "info",
            title: "We have a problem. ",
            description: `Contact the developer team. Code: ${response.status}`,
          });

          setIsVisibleToast(true);
          setTimeout(() => setIsVisibleToast(false), 3000);

          break;
      }
    } catch (error) {
      console.log(error);
      setToastConfig({
        type: "info",
        title: "We have a problem. ",
        description: `Contact the developer team.`,
      });

      setIsVisibleToast(true);
      setTimeout(() => {
        setIsVisibleToast(false);
      }, 3000);
    }
    setOpenUseModal(false);
  };

  const removeThemeHandler = (theme: string) => {
    const blogThemesFilter = blogPost.themes.filter(
      (themeState) => themeState !== theme
    );

    setBlogPost((prevState) => ({
      ...prevState,
      themes: blogThemesFilter,
    }));
  };

  return (
    <DashboardLayout className="blog-publisher">
      <ImageUploader
        buttonText={`add${
          blogPost.images.length >= 1 ? " more images" : " image"
        }`}
        imagesCallback={imagesCallback}
        className="padding-hor-24"
      />
      <InputText
        onChangeInput={(e) => setBlogPost({ ...blogPost, video_link: e })}
        value={blogPost.video_link}
        placeholder="Video link"
        label="Add a link from You Tube"
        className="margin-bottom-16"
      />
      <div className="theme-input-container margin-bottom-16">
        <InputText
          onChangeInput={(e) => setThemeInput(e)}
          value={themeInput}
          placeholder="Add a tag for post"
          label="Tags"
          className="theme-input-container__input"
          onKeyDown={(e) => {
            if (e.key === "Enter") addTheme();
          }}
        />
        <Button
          buttonStyle="secondary"
          onClick={addTheme}
          className="theme-input-container__button"
        >
          Add tag
        </Button>
        <div className="themes-list">
          {blogPost.themes.map((theme, index) => (
            <span key={index} className="theme-item">
              {theme}
              <img
                src={CloseIcon}
                alt="x icon"
                onClick={() => removeThemeHandler(theme)}
              />
            </span>
          ))}
        </div>
      </div>
      <InputText
        onChangeInput={(e) => setBlogPost({ ...blogPost, blog_title: e })}
        value={blogPost.blog_title}
        placeholder="Title"
        label="Title"
        className="margin-bottom-16"
      />
      <TextArea
        label="Content"
        onChangeInput={(e) => {
          setBlogPost({ ...blogPost, blog_text: e });
        }}
        value={blogPost.blog_text}
        placeholder="Content"
        rows={10}
        className="margin-bottom-16"
      />
      <Button
        buttonStyle="primary"
        onClick={blogPublisherHandler}
        className="button-publisher"
      >
        Publish on Blog
      </Button>

      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description="Please fill all the fields correctly."
      />
      <Toast
        type={toastConfig.type}
        isVisible={isVisibleToast}
        setIsVisible={setIsVisibleToast}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </DashboardLayout>
  );
};

export default BlogPublisher;
