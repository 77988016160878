import React from "react";
import "./styles.scss";
import { IPostResponse } from "../../../types/posts";
import { formatDate } from "../../../utils/formatDate";
import ArrowRightGray from "../../../assets/icons/chevron-right-gray.svg";

interface BlogArticlesListProps {
  className?: string;
  onClick: (a: string) => void;
  articles: IPostResponse[];
}

const BlogArticlesList: React.FC<BlogArticlesListProps> = (props) => {
  const { className, articles, onClick } = props;

  return (
    <ul className={className ?? ""} id="blog-articles-list">
      {articles.map((article, index) => (
        <li
          key={index}
          className="blog-articles-list__article"
          onClick={() => onClick(article.id)}
        >
          <h3 className="article__title no-margin">{article.blog_title}</h3>

          <div className="article__info-article">
            <p className="info-article__date-create">
              Created at: {formatDate(article.created_at)}
            </p>
            {article.updated_at !== article.created_at && (
              <p className="info-article__date-update">
                Updated at: {formatDate(article.updated_at)}
              </p>
            )}
          </div>
          <img src={ArrowRightGray} alt="arrow right" />
        </li>
      ))}
    </ul>
  );
};

export default BlogArticlesList;
