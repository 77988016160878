export const formatDate = (isoString: string | Date | undefined) => {
  if (isoString) {
    const date = new Date(isoString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const formattedDate = `${month}/${day}/${year} ${hours}h${minutes}`;
    return formattedDate;
  }
};
