import React from "react";
import "./styles.scss";
import "swiper/css";
import HouseIcon from "../../../assets/icons/house-gray.svg";
import SlideCarousel from "../../Carousels/SlideCarousel";
import Button from "../../Buttons/Button";

interface ShowCasePrincipalProps {
  title: string;
  details: string;
  imagesInfo: ImagesInfo[];
  clientsAmount?: string | number;
  salesAmount?: string | number;
  showTextInfo?: boolean;
  className?: string;
  onClick?: (a: any) => void;
}

interface ImagesInfo {
  id: string;
  img: string;
  price: string;
  address: string;
}

const ShowCasePrincipal: React.FC<ShowCasePrincipalProps> = ({
  details,
  title,
  imagesInfo,
  clientsAmount,
  salesAmount,
  showTextInfo,
  className,
  onClick,
}) => {
  return (
    <div id="showcase-principal" className={className ?? ""}>
      <SlideCarousel
        slides={imagesInfo.map((img) => {
          return { backgroundImage: img.img, id: img.id };
        })}
        className="showcase-principal__carousel"
      />

      <div className="showcase-details-side">
        <div>
          <h3 className="text-wrapper__title no-margin">{title}</h3>
          <p className="text-wrapper__paragraph">{details}</p>
          <Button
            children={"View Details"}
            buttonStyle={"secondary"}
            onClick={onClick}
          />
        </div>

        <div className="images-details-container">
          {showTextInfo ? (
            <div className="text-details">
              <img src={HouseIcon} alt="icon house gray" />
              <p>Clients</p>
              <p>{clientsAmount}</p>
            </div>
          ) : (
            <div className="image-details">
              <img src={imagesInfo[1]?.img} alt="house details" />
            </div>
          )}
          <div className="image-details">
            <img src={imagesInfo[2]?.img} alt="house details" />
          </div>
          <div className="image-details">
            <img src={imagesInfo[3]?.img} alt="house details" />
          </div>
          {showTextInfo ? (
            <div className="text-details">
              <img src={HouseIcon} alt="icon house gray" />
              <p>Sales</p>
              <p>{salesAmount}</p>
            </div>
          ) : (
            <div className="image-details">
              <img src={imagesInfo[4]?.img} alt="house details" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowCasePrincipal;
