import React from "react";
import "./styles.scss";
import Loading from "../Loading";

interface LoadingPageProps {
  loadingText?: string;
}

const LoadingPage: React.FC<LoadingPageProps> = (props) => {
  const { loadingText = "Loading..." } = props;

  return (
    <div className="loading-page">
      <div className="loading-page-wrapper">
        <Loading />
        <p className="loading-page-wrapper__text">{loadingText}</p>
      </div>
    </div>
  );
};

export default LoadingPage;
