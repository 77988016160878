import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import { jadeOcean, imgOra, jadeSignature } from "../../mocks/imagesMock";

//IMAGES BUILDING
import jadeOceanBuilding03 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building03.jpg";
import jadeOceanBuilding04 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building04.jpg";
import jadeOceanBuilding05 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building05.jpg";
import jadeOceanBuilding06 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building06.jpg";

import ShowCasePrincipal from "../../components/ShowCases/ShowCasePrincipal";
import ShowCaseSecondary from "../../components/ShowCases/ShowCaseSecondary";
import TitleParagraph from "../../components/Texts/TitleParagraph";
import BannerCareerDetails from "../../components/Banners/BannerCareerDetails";
import Hr from "../../components/Hr";
import { useNavigate } from "react-router-dom";
import BusinessCard from "../../components/BusinessCard";

const LandingPage: React.FC = () => {
  const [wideScreen, setWideScreen] = useState(window.innerWidth < 765);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWideScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const jadeOceanLink = () => {
    window.location.href = "https://jadeocean4004.miami";
  };

  const jadeSignatureLink = () => {
    window.location.href = "https://jadesignature5105.miami";
  };

  const oraLink = () => {
    navigate("/ora-by-casa-tua");
  };

  return (
    <LayoutDefault
      centeredInfo="info-residence"
      title="Thiago Costa"
      strongTitle=" Miami"
      subInfo="Explore"
      strongSubInfo=" luxury living."
      price="Book your private tour today!"
      // hasImageInFooter
      slidesCarousel={[
        { id: "1", backgroundImage: jadeOceanBuilding04 },
        { id: "2", backgroundImage: jadeOceanBuilding05 },
        { id: "3", backgroundImage: jadeOceanBuilding06 },
        { id: "4", backgroundImage: jadeOceanBuilding03 },
      ]}
      className="landing-page"
    >
      {/* <BannerCareerDetails
        title="Total Career Sales"
        moneyOnHousesSold="$1,000,000,000+"
        extraInfo="$200+ Million Sold 2021-2024"
      />
      <Hr /> */}
      <ShowCasePrincipal
        title="Ora by Casa Tua"
        details={`As one of the top producers for Fortune International in 
					Miami, Florida, Thiago specializes in connecting discerning buyers 
					with the most prestigious properties in the area. Each listing 
					reflects the epitome of luxury, offering unparalleled elegance, 
					breathtaking views, and exceptional amenities.`}
        imagesInfo={imgOra}
        onClick={() => oraLink()}
      />
      <Hr />
      <TitleParagraph
        title="Featured Listings"
        paragraph={`Explore our million-dollar listings and discover the perfect 
					property that aligns with your aspirations. Contact Thiago Costa today 
					to schedule a private viewing and take the first step toward owning a 
					piece of Miami’s most exclusive real estate.`}
      />

      <ShowCaseSecondary
        imagesCarousel={jadeSignature}
        address="16901 COLLINS AVE SUNNY ISLES BEACH, FL"
        price="$15,900,000"
        onClick={() => jadeSignatureLink()}
        className="margin-bottom-24"
      />

      <ShowCaseSecondary
        imagesCarousel={jadeOcean}
        address="17121 COLLINS AVE SUNNY ISLES BEACH, FL"
        price="$3,490,000"
        onClick={() => jadeOceanLink()}
      />
      <Hr />
      <BusinessCard
        className="margin-vert-40"
        // hasImageInFooter={hasImageInFooter}
      />
      <Hr />
    </LayoutDefault>
  );
};

export default LandingPage;
