import React, { useState } from "react";
import "./styles.scss";

import Button from "../../../components/Buttons/Button";
import FadeCarousel from "../../../components/Carousels/FadeCarousel";
import { imgCarousel } from "../../../mocks/imagesMock";
import ThiagoCostaLogo from "../../../assets/logos/logo-text-white.svg";
import loginDashboard from "../../../api/loginDashboard";
import { useNavigate } from "react-router-dom";
import InputText from "../../../components/Inputs/InputText";
import LoginDashBoard from "../../../api/loginDashboard";
import { IToast } from "../../../types/IToast";
import Toast from "../../../components/Toast";

const LoginPage: React.FC = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [toastConfig, setToastConfig] = useState<IToast>({
    type: "info",
    title: "",
    description: "",
  });

  const [isVisibleToast, setIsVisibleToast] = useState(false);

  const navigate = useNavigate();

  const loginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    const response = await loginDashboard({
      email: userEmail,
      password: userPassword,
    });

    switch (response.status) {
      case 200:
        console.log(response.res);
        localStorage.setItem("tc_access_token", response.res.token);

        navigate("/dashboard/blog-publisher");
        break;

      case 401:
        setToastConfig({
          description: "Check your credentials and try again",
          title: "Invalid email or password",
          type: "error",
        });

        setIsVisibleToast(true);
        setEmailWarning(true);
        setPasswordWarning(true);

        setTimeout(() => {
          setIsVisibleToast(false);
          setEmailWarning(false);
          setPasswordWarning(false);
        }, 4000);
        localStorage.removeItem("tc_access_token");

        break;
      default:
        setPasswordWarning(true);
        setEmailWarning(true);
        localStorage.removeItem("tc_access_token");
        setTimeout(() => {
          setUserEmail("");
          setUserPassword("");
          setEmailWarning(false);
          setPasswordWarning(false);
        }, 3000);
    }
  };

  return (
    <div className="login-page">
      <div className="images-login-container">
        <FadeCarousel
          // centeredInfo="tc-logo"
          duration={4000}
          slides={imgCarousel.map((img) => {
            return { backgroundImage: img.img, id: img.id };
          })}
        />
      </div>
      <form className="form-login-page" onSubmit={loginSubmit}>
        <img src={ThiagoCostaLogo} alt="logo Thiago Costa" />
        <InputText
          onChangeInput={(e) => setUserEmail(e)}
          value={userEmail}
          label="Email"
          type="email"
          isWarning={emailWarning}
          whiteLabel
        />

        <InputText
          onChangeInput={(e) => setUserPassword(e)}
          value={userPassword}
          label="Password"
          type="password"
          isWarning={passwordWarning}
          whiteLabel
        />

        <Button
          type="submit"
          buttonStyle="primary"
          className="margin-top-24"
          // disabled={!userEmail || !userPassword || isLoading}
        >
          Login
        </Button>
      </form>

      <Toast
        type={toastConfig.type}
        isVisible={isVisibleToast}
        setIsVisible={setIsVisibleToast}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default LoginPage;
