import fetchApi from "./fetchApi";

const ListPosts = async () => {
  return await fetchApi({
    service: "posts",
    method: "GET",
    endPoint: ``,
  });
};

export default ListPosts;
