import React, { ReactNode } from "react";
import "./styles.scss";
import { slide as Menu } from "react-burger-menu";
import burger from "../../../assets/icons/menu.svg";
import { useNavigate } from "react-router-dom";
import ThiagoCostaLogo from "../../../assets/logos/logo-text-white.svg";

interface AsideDashboardProps {
  children: ReactNode;
  className: string;
}

const AsideDashboard: React.FC<AsideDashboardProps> = (props) => {
  const { children, className } = props;

  const navigate = useNavigate();

  const dashBoardLinks = [
    {
      id: 1,
      name: "Add Property",
      action: () => {},
      isDisabled: true,
    },
    {
      id: 2,
      name: "Property List",
      action: () => {},
      isDisabled: true,
    },
    {
      id: 3,
      name: "Publish on Blog",
      action: () => navigate("/dashboard/blog-publisher"),
      isDisabled: false,
    },
    {
      id: 4,
      name: "Blog Posts List",
      action: () => navigate("/dashboard/list-edit"),
      isDisabled: false,
    },
    {
      id: 5,
      name: "Account Configurations",
      action: () => {},
      isDisabled: true,
    },
    {
      id: 6,
      name: "Images",
      action: () => navigate("/dashboard/upload-images"),
      isDisabled: false,
    },
    {
      id: 7,
      name: "Landing page",
      action: () => navigate("/"),
      isDisabled: false,
    },
  ];

  return (
    <div id="aside-dashboard">
      <div className="aside-dashboard-mobile">
        <Menu customBurgerIcon={<img src={burger} alt="menu burger" />}>
          <ul className="aside-dashboard-mobile__ul">
            {dashBoardLinks.map(({ id, name, action, isDisabled }) => (
              <li
                key={id}
                onClick={action}
                className={`aside-dashboard-mobile__li ${
                  isDisabled ? "disabled" : ""
                }`}
              >
                {name}
              </li>
            ))}
          </ul>
        </Menu>
      </div>
      <div className="aside-dashboard-wide">
        <img src={ThiagoCostaLogo} alt="logo Thiago Costa" />
        <ul className="aside-dashboard-wide__ul">
          {dashBoardLinks.map(({ action, id, isDisabled, name }) => (
            <li
              className={`aside-dashboard-wide__li  ${
                isDisabled ? "disabled" : ""
              }`}
              key={id}
              onClick={action}
            >
              {name}
            </li>
          ))}
        </ul>
      </div>
      <div className={`${className} children`}>{children}</div>
    </div>
  );
};

export default AsideDashboard;
