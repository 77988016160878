import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import LogoWhite from "../../../assets/logos/logo-text-white.svg";
import LogoBlue from "../../../assets/logos/logo-text.svg";
import Button from "../../Buttons/Button";

const NavBar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleTriggerClick = () => {
    const navWrapper = document.querySelector("#nav-bar-landing-page");
    if (navWrapper) {
      navWrapper.classList.toggle("open");
    }
  };

  useEffect(() => {
    const checkInitialScrollState = () => {
      if (document.body.scrollHeight <= window.innerHeight) {
        setIsScrolled(true);
      }
    };

    checkInitialScrollState();

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const listYourPropertyHandler = () => {};

  return (
    <div id="nav-bar-landing-page" className={isScrolled ? "scrolled" : ""}>
      <div
        className="logo-container"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          onClick={() => navigate("/")}
          src={isScrolled ? LogoBlue : LogoWhite}
          alt="Thiago Costa's logo"
        />
      </div>
      <div
        className={`button-container-nav-bar ${isScrolled ? "scrolled" : ""}`}
      >
        <ul className="nav no-margin no-padding">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          {/* <li>
            <a href="/#contact-us">Contact Us</a>
          </li> */}
          <li>
            <a href="/blog">Blog</a>
          </li>
          <a
            href="https://wa.me/13053704780"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1 (305) 370-4780
          </a>
          {/* <Button
            onClick={listYourPropertyHandler}
            buttonStyle={isScrolled ? "tertiary" : "primary"}
            className="list-your-property"
          >
            List Your property
          </Button> */}
        </ul>
      </div>
      <div
        className={
          isScrolled ? "trigger margin-top-8 scrolled" : "trigger margin-top-8"
        }
        onClick={handleTriggerClick}
      ></div>
    </div>
  );
};

export default NavBar;
