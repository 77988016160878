import React, { ReactNode } from "react";
import "./styles.scss";
import Footer from "../../Footer";
import FadeCarousel from "../../Carousels/FadeCarousel";
import NavBarDefault from "../../NavBars/NavBarDefault";

interface LayoutDefaultProps {
  children: ReactNode;
  className?: string;
  hasImageInFooter?: boolean;
  slidesCarousel?: { backgroundImage: string; id: string | number }[];
  hasBusinessCard?: boolean;
  centeredInfo?: "tc-logo" | "info-residence";
  title?: string; // Novo prop
  strongTitle?: string; // Novo prop
  subInfo?: string; // Novo prop
  strongSubInfo?: string; // Novo prop
  price?: string; // Novo prop
}

const LayoutDefault: React.FC<LayoutDefaultProps> = (props) => {
  const {
    children,
    className,
    hasImageInFooter,
    slidesCarousel,
    hasBusinessCard,
    centeredInfo,
    title,
    strongTitle,
    subInfo,
    strongSubInfo,
    price,
  } = props;

  return (
    <div id="layout-default">
      <NavBarDefault />
      {slidesCarousel && (
        <FadeCarousel
          slides={slidesCarousel}
          duration={12000}
          centeredInfo={centeredInfo}
          title={title}
          strongTitle={strongTitle}
          subInfo={subInfo}
          strongSubInfo={strongSubInfo}
          price={price}
        />
      )}
      <main className={`main-container ${className ?? ""}`}>{children}</main>
      <Footer
        hasImageInFooter={hasImageInFooter}
        className="padding-hor-24px"
        hasBusinessCard={hasBusinessCard}
      />
    </div>
  );
};

export default LayoutDefault;
