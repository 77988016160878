import React, { useState, useEffect } from "react";
import "./styles.scss";
import ThiagoLogoWhite from "../../../assets/logos/logo-text-white.svg";
import Button from "../../Buttons/Button";

interface FadeCarouselProps {
  slides: Slides[];
  duration?: number;
  dotsColor?: string;
  centeredInfo?: "tc-logo" | "info-residence" | undefined;
  title?: string;
  strongTitle?: string;
  subInfo?: string;
  strongSubInfo?: string;
  price?: string;
}

interface Slides {
  backgroundImage: string;
  id: string | number;
}

const FadeCarousel: React.FC<FadeCarouselProps> = (props) => {
  const {
    slides,
    duration = 2000,
    dotsColor = "var(--blue-dark)",
    centeredInfo,
    title = "",
    strongTitle = "",
    subInfo = "",
    strongSubInfo = "",
    price = "",
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, duration);

    return () => clearInterval(interval);
  }, [slides.length, duration]);

  return (
    <div id="fade-carousel-images">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`fade-carousel-slide ${
            index === currentIndex ? "active" : ""
          }`}
          style={{ backgroundImage: `url(${slide.backgroundImage})` }}
        >
          <div
            className="slide-background"
            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
          ></div>
        </div>
      ))}
      {centeredInfo === "tc-logo" ? (
        <img src={ThiagoLogoWhite} alt="thiago costa logo" />
      ) : centeredInfo === "info-residence" ? (
        <div className="property-info">
          <h1>
            {title}
            <strong>{strongTitle}</strong>
          </h1>
          <h2>
            {subInfo}
            <strong>{strongSubInfo}</strong>
          </h2>
          <h3>{price}</h3>
          <Button
            children={"Contact Thiago"}
            buttonStyle={"tertiary"}
            onClick={() => {
              const element = document.getElementById("business-card");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          />
        </div>
      ) : (
        false
      )}

      <div className="footer-dots-button">
        <div className="pagination-dots-wrapper">
          {slides.map((_, index) => (
            <div
              onClick={() => setCurrentIndex(index)}
              key={index}
              className={`pagination-dot ${
                index === currentIndex ? "current-dot" : ""
              } `}
              style={{
                borderColor: dotsColor,
                opacity: index === currentIndex ? 1 : 0.5,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FadeCarousel;
