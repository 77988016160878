import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";
import CarouselDefault from "../../Carousels/CarouselDefault";
import SlideCarousel from "../../Carousels/SlideCarousel";

interface ShowCaseSecondaryProps {
  imagesCarousel: {
    id: string;
    img: string;
    // price: string;
    // address: string;
  }[];
  price: string;
  address: string;
  onClick?: (a: any) => void;
  ref?: React.LegacyRef<HTMLDivElement>;
  // autoPlay: boolean;
  // showDetails?: boolean;
  className?: string;
}

const ShowCaseSecondary: React.FC<ShowCaseSecondaryProps> = (props) => {
  const {
    imagesCarousel,
    price,
    address,
    ref,
    onClick,
    // autoPlay,
    // showDetails,
    className,
  } = props;

  const [indexPhotoFirst, setIndexPhotoFirst] = useState(1);
  const [indexPhotoSecond, setIndexPhotoSecond] = useState(1);

  useEffect(() => {
    setIndexPhotoFirst(Math.floor(Math.random() * imagesCarousel.length));
    setIndexPhotoSecond(Math.floor(Math.random() * imagesCarousel.length));
  }, [imagesCarousel.length]);

  return (
    <div id="show-case-secondary" ref={ref} className={className}>
      <SlideCarousel
        slides={imagesCarousel.map((img) => {
          return { backgroundImage: img.img, id: img.id };
        })}
        className="show-case-secondary__carousel"
      />
      <div className="images-details-container">
        <img src={imagesCarousel[indexPhotoFirst].img} alt="home details" />
        <img src={imagesCarousel[indexPhotoSecond].img} alt="home details" />
      </div>
      <div className="home-details-container">
        <h2 className="no-margin">{price}</h2>
        <h3
          className="no-margin"
          dangerouslySetInnerHTML={{
            __html: address,
          }}
        />
        <Button buttonStyle="secondary" onClick={onClick} arrowIcon>
          View details
        </Button>
      </div>
    </div>
  );
};

export default ShowCaseSecondary;
