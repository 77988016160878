import fetchApi from "./fetchApi";

const ListPhotosGallery = async () => {
  return await fetchApi({
    service: "driveFiles",
    method: "GET",
    endPoint: ``,
  });
};

export default ListPhotosGallery;
