import parsePhoneNumberFromString, {
  AsYouType,
  CountryCode,
} from "libphonenumber-js";

export const setupPhoneNumberInput = (
  acronym: CountryCode,
  inputValue: string
) => {
  let phoneMask = new AsYouType(acronym).input(inputValue);

  return phoneMask;
};

export const removeNumberMask = (numero: string) => {
  return numero.replace(/\D/g, "");
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedNumber) {
      const formattedNumber = new AsYouType().input(parsedNumber.number);
      const countryCode = parsedNumber.countryCallingCode
        ? `+${parsedNumber.countryCallingCode}`
        : "";
      const areaCode = parsedNumber.country
        ? ` ${parsedNumber.formatNational().split(" ")[0]}`
        : "";
      const visiblePart = formattedNumber.slice(-4);
      const hiddenPart = "*".repeat(formattedNumber.length - 12);
      return `${countryCode}${areaCode} ${hiddenPart} ${visiblePart}`;
    } else {
      return "Número de telefone inválido.";
    }
  } catch (error) {
    return "Número de telefone inválido.";
  }
};
