import { currentURL } from "./fetchApi/services-api";

const CreatePost = async (token: string, data: FormData) => {
  const response = await fetch(`${currentURL}/posts`, {
    method: "POST",
    body: data,
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export default CreatePost;
