import React from "react";
import "./styles.scss";
import Title from "../Texts/Title";
import Paragraph from "../Texts/Paragraph";
import Hr from "../Hr";
import profilePhoto from "../../assets/images/tc-miami_profile-photo_thiago-costa-01.jpg";

interface ResumeProps {
  className?: string;
}

const Resume: React.FC<ResumeProps> = (props) => {
  const { className } = props;

  return (
    <div id="resume" className={className ?? ""}>
      <div className="resume-texts">
        <Title text="Thiago Costa" />
        <p className="sub-title">Top Producer</p>
        <Paragraph
          text={`Thiago Costa is a top-tier real estate broker with nearly two decades of experience in South Florida's luxury market, boasting over $1 billion in career sales. With a background in real estate appraisal, Thiago has unmatched expertise in property valuation and market trends, allowing him to deliver exceptional results for his clients. His personal approach, fluency in Portuguese, and deep connections with top developers make him the go-to advisor for international buyers, especially from Brazil, seeking the best opportunities in Miami's prestigious neighborhoods."`}
        />
        <Paragraph
          text={`Known for his dedication, meticulous attention to detail, and post-sale concierge services, Thiago builds lasting relationships with his clients, ensuring their success long after the deal is closed. Specializing in high-rise condos, waterfront properties, and ultra-luxury homes, he continues to redefine luxury real estate with an unwavering commitment to excellence and client satisfaction.`}
        />
        <Hr />
        <section className="contact-info-container">
          <h3>Contact Thiago</h3>

          <table>
            <tbody className="contact-info-container__table-tbody">
              <tr>
                <td className="table-key">Phone:</td>
                <td className="table-value">
                  <a
                    href="https://wa.me/13053704780"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +1 (305) 370-4780
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-key">Email:</td>
                <td className="table-value">
                  <a className="email-link" href="mailto:tcosta@fir.com">
                    tcosta@fir.com
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-key">Office:</td>
                <td className="table-value">
                  Fortune Christie's International
                  <br />
                  1390 Brickell Avenue, Suite 104
                  <br />
                  Miami, FL 33131
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <img src={profilePhoto} alt="Thiago profile" />
    </div>
  );
};

export default Resume;
