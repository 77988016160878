import Paragraph from "../Paragraph";
import Title from "../Title";
import "./styles.scss";

interface TitleParagraphProps {
  title: string;
  paragraph: string;
  marginTop?: string | number;
  marginBottom?: string | number;
  headerAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | undefined;
  className?: string;
}

const TitleParagraph: React.FC<TitleParagraphProps> = (props) => {
  const { paragraph, title, marginBottom, marginTop, headerAlign, className } =
    props;

  return (
    <div
      id="title-paragraph"
      style={{ marginTop, marginBottom }}
      className={className ?? ""}
    >
      <Title text={title} textAlign={headerAlign} />
      <Paragraph text={paragraph} />
    </div>
  );
};

export default TitleParagraph;
