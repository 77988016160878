import React, { useEffect, useState } from "react";
import "./styles.scss";
import ArrowLeft from "../../assets/icons/arrow-left-gray.svg";
import { useNavigate } from "react-router-dom";

interface PaginationProps {
  actualPage: number;
  clickCallback: (page: number) => void;
  totalPages: number;
  previousPageText: string;
  nextPageText: string | undefined;
}

const Pagination: React.FC<PaginationProps> = ({
  actualPage,
  clickCallback,
  totalPages,
  nextPageText,
  previousPageText,
}) => {
  const [numberPage, setNumberPage] = useState(actualPage);

  const navigate = useNavigate();

  useEffect(() => {
    setNumberPage(actualPage);
  }, [actualPage]);

  const nextPage = () => {
    if (numberPage < totalPages) {
      const newPage = numberPage + 1;
      setNumberPage(newPage);
      clickCallback(newPage);
    } else {
      navigate("/blog");
    }
  };

  const prevPage = () => {
    if (numberPage > 1) {
      const newPage = numberPage - 1;
      setNumberPage(newPage);
      clickCallback(newPage);
    } else {
      navigate("/blog");
    }
  };

  return (
    <div id="pagination-component">
      <p className="previous-text" onClick={prevPage}>
        <img
          src={ArrowLeft}
          alt="arrow left"
          className={`arrow ${numberPage === 1 ? "disabled" : ""}`}
        />
        {previousPageText}
      </p>

      {/* <p className="number-page">{numberPage}</p>*/}
      {nextPageText ? (
        <p className="next-text" onClick={nextPage}>
          {nextPageText}
          <img
            src={ArrowLeft}
            alt="arrow right"
            onClick={nextPage}
            className={`arrow ${numberPage === totalPages ? "disabled" : ""}`}
          />
        </p>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Pagination;
