import fetchApi from "./fetchApi";

const DeletePostByID = async (token: string, id: string) => {
  return await fetchApi({
    service: "posts",
    method: "DELETE",
    endPoint: `/${id}`,
    token,
  });
};

export default DeletePostByID;
