import React from "react";
import "./styles.scss";
import { IButtonStyle } from "../../types/IButtonStyle";
import Button from "../Buttons/Button";
import SlideCarousel from "../Carousels/SlideCarousel";

interface ImageAndTextProps {
  className?: string;
  position:
    | "vert-photo-text"
    | "vert-text-photo"
    | "hor-photo-text"
    | "hor-text-photo";
  images: { img: string; id: string | number }[];
  textTitle: string;
  textParagraph: string;
  gallery?: boolean;
  button?: {
    buttonStyle: IButtonStyle;
    buttonText: string;
    buttonClick: (event: any) => void;
    buttonRightIcon?: string;
  };
  linkTo?: {
    linkHref: string;
    linkText: string;
  };
}

const ImageAndText: React.FC<ImageAndTextProps> = (props) => {
  const {
    className,
    position,
    images,
    textParagraph,
    textTitle,
    button,
    gallery,
    linkTo,
  } = props;
  return (
    <section id="image-and-text-component" className={className ?? ""}>
      <div className={position}>
        <SlideCarousel
          slides={images.map((img) => {
            return { backgroundImage: img.img, id: img.id };
          })}
          className={`image-and-text-component__slide ${
            gallery ? "gallery-height" : ""
          }`}
          dotsColor="var(--white)"
        />

        <div className="image-and-text-component__text-wrapper">
          <h3>{textTitle}</h3>
          <p>{textParagraph}</p>

          {button && (
            <div className="image-and-text-component__button-wrapper">
              <Button
                buttonStyle={button.buttonStyle}
                onClick={button.buttonClick}
              >
                {button.buttonText}
                <img src={button.buttonRightIcon} alt="icon button right" />
              </Button>
            </div>
          )}
          {linkTo && (
            <div className="image-and-text-component__linkto">
              <a
                href={linkTo.linkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkTo.linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImageAndText;
