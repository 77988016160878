import React, { ReactNode } from "react";
import "./styles.scss";
import ArrowRightGray from "../../../assets/icons/chevron-right-gray.svg";
import { IButtonStyle } from "../../../types/IButtonStyle";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  buttonStyle: IButtonStyle;
  arrowIcon?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { buttonStyle, children, arrowIcon, className, ...rest } = props;
  return (
    <button
      id="button-default"
      className={`${className} ${buttonStyle}${
        buttonStyle === "quintenary" ? " background-blur-gray " : ""
      }`}
      {...rest}
    >
      {children}
      {arrowIcon && <img src={ArrowRightGray} alt="arrow right" />}
    </button>
  );
};

export default Button;
