const base_url_prod = "https://tc-miami-b13a5bdf8cf2.herokuapp.com";
const base_url_dev = "http://localhost:3333";

export const currentURL = base_url_prod;

const servicesAPI = {
  posts: `${currentURL}/posts`,
  login: `${currentURL}/signin`,
  sendContactEmail: `${currentURL}/send-contact-email`,
  driveFiles: `${currentURL}/drive-files`,
} as const;

export type ServiceType = keyof typeof servicesAPI;

export const servicesApi = <T extends ServiceType>(
  service: T
): (typeof servicesAPI)[T] => {
  return servicesAPI[service];
};
