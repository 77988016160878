import React, { ReactNode } from "react";
import "./styles.scss";
import AsideDashboard from "../../Asides/AsideDashboard";

interface DashboardLayoutProps {
  children: ReactNode;
  className: string;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children, className } = props;

  return (
    <div id="dashboard-layout">
      <AsideDashboard className={className}>{children}</AsideDashboard>
    </div>
  );
};

export default DashboardLayout;
