import fetchApi from "./fetchApi";

const LoginDashBoard = async (data: { email: string; password: string }) => {
  return await fetchApi({
    service: "login",
    method: "POST",
    endPoint: ``,
    data,
  });
};

export default LoginDashBoard;
