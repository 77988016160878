import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";

import Resume from "../../components/Resume";
import Hr from "../../components/Hr";

const AboutUsPage: React.FC = () => {
  const [wideScreen, setWideScreen] = useState(window.innerWidth < 765);

  useEffect(() => {
    const handleResize = () => {
      setWideScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LayoutDefault hasImageInFooter hasBusinessCard={false}>
      <Resume className="margin-top-80" />
      <Hr />
    </LayoutDefault>
  );
};

export default AboutUsPage;
