import { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../../components/Wrappers/LayoutDefault";
import ListPosts from "../../../api/listPosts";
import Title from "../../../components/Texts/Title";
import YouTubeFrame from "../../../components/YouTubeFrame";
import Pagination from "../../../components/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import Hr from "../../../components/Hr";
import { formatDate } from "../../../utils/formatDate";
import useScrollToTop from "../../../hooks/useScrollToTop";

const BlogArticlesPage = () => {
  useScrollToTop();

  const [postImages, setPostImages] = useState("");
  const [postText, setPostText] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postThemes, setPostThemes] = useState("");
  const [postVideoLink, setPostVideoLink] = useState("");
  const [parts, setParts] = useState([]);
  const [pagesQuantity, setPagesQuantity] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [datePost, setDatePost] = useState("");
  const [tagPost, setTagsPost] = useState([]);
  const [nextTitle, setNextTitle] = useState(undefined);

  const { pageNumber } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setActualPage(Number(pageNumber));
    const postsBlog = async () => {
      const blogPosts = await ListPosts();

      switch (blogPosts.status) {
        case 200:
          const listBlogArray = blogPosts.res.reverse();

          setNextTitle(listBlogArray[actualPage]?.blog_title);

          setPostImages(listBlogArray[actualPage - 1]?.images);
          setPostText(listBlogArray[actualPage - 1]?.blog_text);
          setPostTitle(listBlogArray[actualPage - 1]?.blog_title);
          setPostThemes(listBlogArray[actualPage - 1]?.themes);
          setPostVideoLink(listBlogArray[actualPage - 1]?.video_link);
          setPagesQuantity(blogPosts.res.length);
          setDatePost(
            formatDate(listBlogArray[actualPage - 1]?.created_at) as string
          );
          setTagsPost(listBlogArray[actualPage - 1]?.themes);

          let regex = /(\{image\d+\})/g;

          let result = listBlogArray[actualPage - 1]?.blog_text
            .split(regex)
            .filter(Boolean);

          let transformedResult = result?.map((item: string) => {
            let match = item.match(/\{image(\d+)\}/);
            if (match) {
              return parseInt(match[1], 10);
            } else {
              return item;
            }
          });

          setParts(
            transformedResult?.map((text: string | number, index: number) => {
              if (typeof text === "number") {
                return (
                  <img
                    key={index}
                    src={listBlogArray[actualPage - 1]?.images[text - 1]}
                    alt={`figure${text}`}
                    className="image-from-blog"
                  />
                );
              } else {
                return (
                  <p
                    className="text-from-blog"
                    key={index}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                );
              }
            })
          );
          break;
        default:
          console.log("error");
      }
    };

    postsBlog();
  }, [actualPage]);

  const paginationCallback = (pageNumber: number) => {
    setActualPage(pageNumber);
    navigate(`/blog/${pageNumber}`);
  };

  return (
    <LayoutDefault className="blog-articles-page">
      <div className="title-image-principal">
        <Pagination
          actualPage={actualPage}
          clickCallback={paginationCallback}
          totalPages={pagesQuantity}
          nextPageText={nextTitle}
          previousPageText="Previous Post"
        />
        <Title text={postTitle} />
        <p className="author-post">
          <span>By </span>Thiago Costa <p className="date-post">{datePost}</p>
        </p>
        <div className="tags-post">
          {tagPost?.map((tag: string, index: number) => (
            <p className="tags-post-text" key={index}>
              {tag}
            </p>
          ))}
        </div>

        <Hr />

        {postVideoLink && <YouTubeFrame videoURL={postVideoLink} />}
      </div>

      <div className="blog-article">{parts}</div>
      <Hr />
      <Pagination
        actualPage={actualPage}
        clickCallback={paginationCallback}
        totalPages={pagesQuantity}
        nextPageText={nextTitle}
        previousPageText="Previous Post"
      />
    </LayoutDefault>
  );
};

export default BlogArticlesPage;
