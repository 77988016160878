import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Button from "../../Buttons/Button";
import "./styles.scss";
import CloseIcon from "../../../assets/icons/close-circle-icon-blue.svg";

interface ImageUploaderProps {
  buttonText: string;
  imagesCallback: (a: (File | string)[]) => void;
  className?: string;
  images?: (File | string)[];
}

const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const { buttonText, imagesCallback, className, images } = props;
  const [imagesToUpload, setImagesToUpload] = useState<(File | string)[]>([]);
  const inputImageUploaderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (images) {
      setImagesToUpload(images);
    }
  }, [images]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const validImages = fileArray.filter((file) =>
        file.type.startsWith("image/")
      );
      const updatedImages = [...imagesToUpload, ...validImages];
      setImagesToUpload(updatedImages);
      imagesCallback(updatedImages);

      if (inputImageUploaderRef.current) {
        inputImageUploaderRef.current.value = "";
      }
    }
  };

  const handleButtonClick = () => {
    if (inputImageUploaderRef.current) {
      inputImageUploaderRef.current.click();
    }
  };

  const deleteImage = (indexToDelete: number) => {
    const updatedImages = imagesToUpload.filter(
      (_, index) => index !== indexToDelete
    );
    setImagesToUpload(updatedImages);
    imagesCallback(updatedImages);
  };

  const getImageSrc = (image: File | string) => {
    return typeof image === "string" ? image : URL.createObjectURL(image);
  };

  return (
    <div id="image-uploader" className={className ?? ""}>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        ref={inputImageUploaderRef}
        className="input-file"
      />
      <div className="image-thumbnail">
        {imagesToUpload.map((image, index) => (
          <div key={index} className="thumbnail-container">
            <img
              src={getImageSrc(image)}
              alt={`upload-${index}`}
              className="thumbnail"
            />
            <img
              src={CloseIcon}
              alt="delete icon"
              className="delete-icon"
              onClick={() => deleteImage(index)}
            />
          </div>
        ))}
      </div>
      <Button
        buttonStyle="secondary"
        onClick={handleButtonClick}
        className="margin-24"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ImageUploader;
