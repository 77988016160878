import { servicesApi, ServiceType } from "./services-api";

type HttpMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

interface FetchApiProps {
  endPoint?: string;
  service: ServiceType;
  method: HttpMethod;
  data?: FormData | object;
  token?: string;
  headers?: Record<string, string>;
}

const fetchApi = async (props: FetchApiProps) => {
  const { endPoint, method, service, data, token, headers } = props;

  const apiRoute = `${servicesApi(service)}${endPoint ? endPoint : ""}`;

  const finalHeaders: HeadersInit = {};

  if (token) {
    finalHeaders.Authorization = `Bearer ${token}`;
  }

  if (headers) {
    Object.entries(headers).forEach(([key, value]) => {
      finalHeaders[key] = value;
    });
  }

  const config: RequestInit = {
    method,
    headers: finalHeaders,
  };

  if (data && ["POST", "PATCH", "PUT"].includes(method)) {
    config.body = JSON.stringify(data);
    finalHeaders["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(apiRoute, config);

    const contentType = response.headers.get("Content-Type") || "";
    let res;

    if (contentType.includes("application/json")) {
      res = await response.json();
    } else {
      res = await response.text();
    }

    return { res, status: response.status };
  } catch (error) {
    console.error("Erro na requisição:", error);
    return { res: null, status: 500 };
  }
};

export default fetchApi;
