import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import { jadeOcean, imgOra } from "../../mocks/imagesMock";
import ImageAndText from "../../components/ImageAndText";
import YouTubeFrame from "../../components/YouTubeFrame";
import Hr from "../../components/Hr";
import Button from "../../components/Buttons/Button";

//IMAGES
import oraMap from "../../assets/images/ora/ora-map.jpg";

//PLANS
import oraAboveBosco from "../../assets/images/ora/plans/tc-miami_ora-above-bosco.jpg";
import oraBelowBosco from "../../assets/images/ora/plans/tc-miami_ora-below-bosco.jpg";

//EXTERNAL
import oraExternal01 from "../../assets/images/ora/ora_external_01.jpg";
import oraExternal02 from "../../assets/images/ora/ora_external_02.jpg";
import oraExternal03 from "../../assets/images/ora/ora-external.jpeg";
import oraExternal04 from "../../assets/images/ora/ora-external2.jpeg";
import oraExternal05 from "../../assets/images/ora/ora-external3.jpeg";

//PHRASES
import oraPhrase01 from "../../assets/images/ora/designed-to-honor.svg";
import oraPhrase02 from "../../assets/images/ora/greatest-amenity.svg";
import oraPhrase03 from "../../assets/images/ora/nature-immersed-sanctuary.svg";
import oraPhrase04 from "../../assets/images/ora/life_happens.svg";

//TEAM
import tcMiamiProfile from "../../assets/images/tc-miami-thiago-costa.jpg";
import tcMiamiFortune from "../../assets/images/tc-miami-fortune.jpg";

//LIFESTYLE
import oraLifestyle01 from "../../assets/images/ora/life/oraLifestyle01.jpg";
import oraLifestyle02 from "../../assets/images/ora/life/oraLifestyle02.jpg";
import oraLifestyle03 from "../../assets/images/ora/life/oraLifestyle03.jpeg";
import oraLifestyle04 from "../../assets/images/ora/life/oraLifestyle04.jpg";
import BusinessCard from "../../components/BusinessCard";

const OraProjectPage: React.FC = () => {
  const [wideScreen, setWideScreen] = useState(window.innerWidth < 765);

  useEffect(() => {
    const handleResize = () => {
      setWideScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutDefault
      centeredInfo="info-residence"
      title="ORA by"
      strongTitle=" Casa Tua"
      subInfo="Explore vertical"
      strongSubInfo=" luxury living."
      price="Book your private meet today!"
      //hasImageInFooter
      slidesCarousel={[
        { id: "1", backgroundImage: oraExternal04 },
        { id: "2", backgroundImage: oraExternal02 },
        { id: "3", backgroundImage: oraExternal01 },
        { id: "4", backgroundImage: oraExternal03 },
      ]}
      className="ora-project-page"
    >
      <div className="main-info">
        <img className="phrase-img padding-bottom-40" src={oraPhrase01} />
        <h1>Discover ORA by Casa Tua</h1>
        <p>
          At ORA by Casa Tua, we aim to redefine luxury living by creating a
          soulful community where design, nature, and human connection are at
          the core of every experience. Inspired by Casa Tua’s values, ORA is
          more than a residence—it is a vibrant destination that blends curated
          design with impeccable hospitality, offering a sanctuary of beauty and
          tranquility in the heart of Miami’s Brickell district. ORA embodies a
          new paradigm of urban living, where luxury meets flexibility, and
          residents can live, work, and play without boundaries. From gourmet
          dining to serene outdoor spaces, every detail is crafted to inspire
          deeper connections to oneself, others, and the natural world, creating
          a timeless, forward-thinking community for those seeking more beauty,
          connection, and life.
        </p>
      </div>
      <Hr />

      <YouTubeFrame videoURL={"https://www.youtube.com/watch?v=539QoapbfLg"} />

      {/* <div className="video">
        <iframe
          src="https://player.vimeo.com/video/806352821"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="ORA by Casa Tua - Video"
          className="video-iframe"
        ></iframe>
      </div> */}
      {/* <HomeListDetails
        details={jadeOceanInfo.principal}
        specifications={jadeOceanInfo.specifications}
      /> */}

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraExternal02 }]}
        position="vert-text-photo"
        gallery
        textTitle="Central Gathering Space for Everyday Moments"
        textParagraph={`At ORA by Casa Tua, the communal areas are thoughtfully designed to serve as a central gathering space that enhances both everyday moments and special occasions. These inviting environments foster connections among residents, encouraging social interactions and creating a sense of community. Whether enjoying a casual morning coffee or hosting a lavish event, the beautifully designed spaces provide the perfect backdrop for memorable experiences.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: imgOra[3].img }]}
        position="hor-text-photo"
        textTitle="Luxurious Bedrooms & Spa-Inspired Baths"
        textParagraph={`The bedrooms at ORA are designed as sanctuaries of peace and relaxation. The master suites offer ample closet space, ensuring both convenience and elegance. Each bedroom is spacious, with large windows that allow natural light to flow in, creating a warm and inviting atmosphere. The en-suite bathrooms are nothing short of spa-inspired, featuring imported Italian fixtures, elegant vanities, and spacious showers or deep soaking tubs. Every detail, from the designer lighting to the finely crafted finishes, is designed to provide a sense of indulgence and relaxation.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: imgOra[1].img }]}
        position="hor-photo-text"
        textTitle="Smart Home Technology & Connectivity"
        textParagraph={`Every residence at ORA by Casa Tua is equipped with the latest smart home technology, allowing residents to control their environment with ease. Whether adjusting the lighting, temperature, or security systems, every aspect of the home can be managed effortlessly. The residences are also pre-wired for high-speed internet and Wi-Fi, ensuring you are always connected, whether working from home or enjoying a seamless entertainment experience.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: imgOra[2].img }]}
        position="hor-text-photo"
        textTitle="Panoramic Views & Flexible Living"
        textParagraph={`With residences offering panoramic views of Miami’s Brickell, Biscayne Bay, and beyond, ORA redefines urban living. The large windows and expansive terraces frame the vibrant beauty of the city and its natural surroundings, providing residents with ever-changing vistas from sunrise to sunset. Each residence has been thoughtfully designed to adapt to your unique lifestyle. Whether you’re a frequent traveler, a family looking for more space, or a professional who values flexible living, ORA offers a variety of layouts that cater to every need. The flexible ownership model allows for short-term rentals (as short as three days), seasonal stays, or long-term living, making it an ideal choice for both primary residents and investors.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: imgOra[4].img }]}
        position="vert-text-photo"
        gallery
        textTitle="Thoughtfully Designed for Well-Being"
        textParagraph={`At ORA by Casa Tua, every residence is designed to promote well-being. The architecture, interiors, and surrounding environment are all curated to enhance peace of mind, relaxation, and a sense of connection to the world around you. With access to world-class amenities, including wellness centers, resort-style pools, and beautifully landscaped gardens, living at ORA goes beyond the residence itself—it’s a lifestyle centered on well-being, balance, and joy. The residences at ORA are not just places to live—they are spaces to experience life in its most luxurious, connected, and vibrant form. Every detail is designed with you in mind, offering a modern sanctuary that feels intuitive, thoughtful, and effortlessly elegant.`}
      />
      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: imgOra[1].img },
          { id: "1", img: imgOra[2].img },
          { id: "1", img: imgOra[3].img },
          { id: "1", img: imgOra[4].img },
        ]}
        gallery
        position="vert-text-photo"
        textTitle="Explore the Beauty of ORA by Casa Tua"
        textParagraph={`Step into the world of ORA by Casa Tua through our carefully curated gallery of renderings, where vision meets reality. Each image captures the essence of our residences, showcasing the seamless blend of modern design, lush natural surroundings, and luxurious amenities. From breathtaking interiors to panoramic views of Miami’s skyline and Biscayne Bay, our gallery offers a glimpse into the refined lifestyle that awaits you at ORA. Explore the beauty and sophistication that define every detail of this exceptional living experience.`}
      />
      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: oraBelowBosco },
          { id: "2", img: oraAboveBosco },
        ]}
        position="vert-text-photo"
        gallery
        textTitle="Floor Plans"
        textParagraph={`Discover the thoughtfully designed floor plans of ORA by Casa Tua, where luxury and functionality come together to create your perfect home. From elegant studios to expansive penthouses, each residence offers open-concept layouts, panoramic views, and seamless indoor-outdoor living. Explore the floor plans to find the space that best fits your lifestyle, and experience the ultimate blend of sophisticated design and modern convenience. Download the floor plans now to envision life at ORA.`}
      />
      <h4>Below Bosco Levels 12-42 </h4>
      <div className="horizontal-button-list">
        <div className="vertical-buttons">
          <h5>One Bedroom</h5>
          <Button
            children={"Line 02"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/One Bedroom/ORA-CasaTua-Digital-FloorPlan-Residence B_Line 02√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence B_Line 02√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 03"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/One Bedroom/ORA-CasaTua-Digital-FloorPlan-Residence C_Line 03√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence C_Line 03√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 04"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/One Bedroom/ORA-CasaTua-Digital-FloorPlan-Residence D_Line 04√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence D_Line 04√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 07"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/One Bedroom/ORA-CasaTua-Digital-FloorPlan-Residence G_Line 07√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence G_Line 07√ .pdf";
              link.click();
            }}
          />
        </div>
        <div className="vertical-buttons">
          <h5>Studios</h5>
          <Button
            children={"Line 08"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Studios/ORA-CasaTua-Digital-FloorPlan-Residence H_Line 08√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence H_Line 08√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 09"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Studios/ORA-CasaTua-Digital-FloorPlan-Residence J_Line 09√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence J_Line 09√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 10"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Studios/ORA-CasaTua-Digital-FloorPlan-Residence K_Line 10√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence K_Line 10√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 11"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Studios/ORA-CasaTua-Digital-FloorPlan-Residence L_Line 11√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence L_Line 11√ .pdf";
              link.click();
            }}
          />
        </div>
        <div className="vertical-buttons">
          <h5>Two Bedrooms</h5>
          <Button
            children={"Line 01"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence A_Line 01√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence A_Line 01√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 05"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence E_Line 05√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence E_Line 05√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 06"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence F_Line 06√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence F_Line 06√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 12"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Below Bosco Levels 12-42/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence M_Line 12√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence M_Line 12√ .pdf";
              link.click();
            }}
          />
        </div>
      </div>
      <h4>Above Bosco Levels 45-74 </h4>
      <div className="horizontal-button-list">
        <div className="vertical-buttons">
          <h5>Two Bedrooms</h5>
          <Button
            children={"Line 02"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence P_Line 02√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence P_Line 02√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 05"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence S_Line 05√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence S_Line 05√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 06"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Two Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence T_Line 06√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence T_Line 06√ .pdf";
              link.click();
            }}
          />
        </div>
        <div className="vertical-buttons">
          <h5>Three Bedrooms</h5>
          <Button
            children={"Line 03"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Three Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence Q_Line 03√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence Q_Line 03√ .pdf";
              link.click();
            }}
          />
        </div>
        <div className="vertical-buttons">
          <h5>Four Bedrooms</h5>
          <Button
            children={"Line 01"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Four Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence N_Line 01√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence N_Line 01√ .pdf";
              link.click();
            }}
          />
          <Button
            children={"Line 04"}
            buttonStyle={"secondary"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = `${process.env.PUBLIC_URL}/downloads/ora/plans/Above Bosco Levels 45-74/Four Bedrooms/ORA-CasaTua-Digital-FloorPlan-Residence R_Line 04√ .pdf`;
              link.download =
                "ORA-CasaTua-Digital-FloorPlan-Residence R_Line 04√ .pdf";
              link.click();
            }}
          />
        </div>
      </div>
      <Hr />
      <div className="main-info">
        <img className="phrase-img padding-vert-40" src={oraPhrase02} />
      </div>
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraLifestyle01 }]}
        position="vert-text-photo"
        gallery
        textTitle="World-Class Dining at ORA"
        textParagraph={`Dining at ORA by Casa Tua is a luxurious culinary journey, with four world-class restaurants offering diverse experiences. Terra, a vibrant 24-hour gourmet market and café, serves as the social hub with freshly brewed coffee, house-made pastries, and gourmet groceries. UVA, an intimate wine bar, boasts over 500 sommelier-selected wines and Mediterranean dishes. Fuoco showcases the art of wood-fired cooking with sustainably sourced ingredients and a menu of crudos and grilled specialties. Vento, the rooftop crown jewel, offers breathtaking views, live performances, and a private lounge, creating an unforgettable dining and social experience.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraLifestyle04 }]}
        position="hor-photo-text"
        textTitle="Wellness and Fitness at ORA"
        textParagraph={`At ORA by Casa Tua, luxury extends beyond each residence with state-of-the-art amenities designed to elevate daily living. The building offers a range of wellness and fitness features, including a fully equipped Fitness Center with the latest machines, group classes, and an outdoor area for exercise under the Miami sun. The Wellness Center provides a sanctuary for relaxation with private treatment rooms, steam and sauna areas, and a serene terrace perfect for mindfulness and meditation. Every detail is crafted to enhance well-being and offer a peaceful retreat from the busy city.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraLifestyle02 }]}
        position="hor-text-photo"
        textTitle="Resort-Style Pool and Outdoor Spaces"
        textParagraph={`For leisure, the resort-style pool is a highlight, surrounded by lush landscaping and offering private cabanas, sun shelves, and poolside services. High above the city, Bosco, ORA’s 40-foot sky park, provides a tranquil escape for outdoor gatherings, yoga sessions, and cultural programming. These outdoor spaces create an intimate yet expansive environment for residents to connect with nature and enjoy Miami’s vibrant energy from a serene perspective.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraLifestyle03 }]}
        position="hor-photo-text"
        textTitle="Social and Entertainment Spaces"
        textParagraph={`ORA also offers dynamic social and entertainment spaces to foster community and create memorable experiences. The Clubroom, adjacent to the pool, is perfect for private events or casual gatherings, while the Social Lounge offers a cozy retreat with workspaces and reading nooks. At the rooftop Vento experience, residents can enjoy live performances, fire pits, and breathtaking views of the Miami skyline, creating an atmosphere of celebration and connection both day and night.`}
      />

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: oraMap }]}
        position="hor-text-photo"
        textTitle="The Location: Brickell"
        textParagraph={`ORA by Casa Tua, nestled in the heart of Miami’s Brickell district, offers a perfect blend of luxury and urban living. Surrounded by top financial institutions, high-end boutiques, and premier dining, it provides residents with both convenience and elegance. The nearly 1,000-foot-high terraces offer stunning views of Biscayne Bay and the city skyline, while lush landscaping creates a serene escape from the bustling streets. Located minutes from Brickell City Centre and close to Wynwood and the Design District, ORA grants easy access to Miami’s best shopping, dining, and cultural scenes, all with unmatched connectivity to the rest of the city and beyond.`}
        linkTo={{
          linkHref: `https://maps.app.goo.gl/34YRikKpfH9d4NRJ8`,
          linkText: "1300 BRICKELL AVE | MIAMI, FL",
        }}
      />
      <Hr />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: tcMiamiProfile }]}
        position="hor-text-photo"
        textTitle="Luxury Real Estate Expertise at ORA by Casa Tua"
        textParagraph={`Thiago Costa, Sales Executive at ORA by Casa Tua, leverages his extensive experience in South Florida’s luxury real estate market, with over a billion dollars in career sales. Known for his expertise in property valuation, market trends, and negotiation skills, Thiago has carved a niche in working with foreign investors, particularly from Brazil. His fluency in Portuguese allows him to expertly manage cross-border transactions, providing his clients with seamless, personalized service. Fortune International Group, the developer behind ORA, is a trusted leader in South Florida’s luxury real estate market with over four decades of experience. Renowned for delivering iconic properties that combine sophisticated design with innovative living solutions, Fortune is committed to setting new standards in modern urban living.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: tcMiamiFortune }]}
        position="hor-photo-text"
        textTitle="A Collaborative Vision for Modern Living"
        textParagraph={`ORA by Casa Tua is brought to life through a collaboration with prestigious partners. Casa Tua, the lifestyle brand known for refined hospitality and soulful elegance, infuses its warmth and sophistication into every aspect of the residence. Arquitectonica, the global architecture firm, has designed ORA’s nearly 1,000-foot structure, blending contemporary architecture with its natural surroundings. M2Atelier, the Milan-based interior design studio, has crafted luxurious, intuitive interiors, while ArquitectonicaGEO’s landscape architecture enhances the property with lush, tropical designs that create a serene, nature-infused environment. Together, these renowned firms ensure ORA is a modern oasis of beauty, function, and tranquility.`}
      />
      <Hr />
      <div className="main-info">
        <img className="phrase-img padding-vert-40" src={oraPhrase04} />
      </div>

      <BusinessCard
        className="margin-vert-40"
        // hasImageInFooter={hasImageInFooter}
      />
      <Hr />

      {/* <ShowCaseTertiary
        details={oraProjectInfo.principal}
        imagesGrid={imgOra.map((img) => {
          return { img: img.img };
        })}
        specifications={oraProjectInfo.specifications}
      /> */}
    </LayoutDefault>
  );
};

export default OraProjectPage;
