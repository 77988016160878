import React from "react";
import "./styles.scss";

interface HrProps {
  className?: string;
}

const Hr: React.FC<HrProps> = (props) => {
  const { className } = props;
  return <div id="separator-default" className={className ?? ""} />;
};

export default Hr;
