import React, { ReactNode } from "react";
import "./styles.scss";

interface ParagraphProps {
  text: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ text }) => {
  return <p id="paragraph-component">{text}</p>;
};

export default Paragraph;
