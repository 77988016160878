import React from "react";
import "./styles.scss";

interface TitleProps {
  text: string;
  textAlign?: "start" | "end" | "left" | "right" | "center" | "justify";
  className?: string;
}

const Title: React.FC<TitleProps> = (props) => {
  const { text, textAlign = "left", className } = props;

  return (
    <h3
      id="title-component no-margin"
      className={className ?? ""}
      style={{ textAlign }}
    >
      {text}
    </h3>
  );
};

export default Title;
