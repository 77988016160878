import React, { useEffect, useState } from "react";
import "./styles.scss";
import DashboardLayout from "../../../components/Wrappers/DashboardLayout";
import TextArea from "../../../components/TextArea";
import ImageUploader from "../../../components/Inputs/ImageUpload";
import Button from "../../../components/Buttons/Button";
import CloseIcon from "../../../assets/icons/close-circle-icon-blue.svg";
import Toast from "../../../components/Toast";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useNavigate } from "react-router-dom";
import InputText from "../../../components/Inputs/InputText";
import { IPost, IPostResponse } from "../../../types/posts";
import { IToast } from "../../../types/IToast";
import { useModal } from "../../../hooks/useModal";
import LoadingPage from "../../../components/LoadingPage";
import BlogArticlesList from "../../../components/Lists/BlogArticlesList";
import ListPosts from "../../../api/listPosts";
import PutPostById from "../../../api/putPostById";
import DeletePostByID from "../../../api/deletePostById";

const ListBlogEditPage: React.FC = () => {
  useScrollToTop();
  const { setModalContent, setOpenUseModal } = useModal();

  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [themeInput, setThemeInput] = useState("");
  const [blogPost, setBlogPost] = useState<IPost>({
    blog_title: "",
    themes: [],
    blog_text: "",
    images: [],
    video_link: "",
    id: "",
  });
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: "info",
    title: "",
    description: "",
  });

  const [isVisibleToast, setIsVisibleToast] = useState(false);
  const [token, setToken] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [articles, setArticles] = useState<IPostResponse[] | []>([]);
  const [articlesSearch, setArticlesSearch] = useState<IPostResponse[] | []>(
    []
  );
  const [articleToSearch, setArticleToSearch] = useState("");

  useEffect(() => {
    const auth = localStorage.getItem("tc_access_token");

    if (!auth) {
      navigate("/dashboard");
    } else {
      setToken(auth);
    }
  }, []);

  useEffect(() => {
    listArticles();
  }, []);

  const listArticles = async () => {
    try {
      const response = await ListPosts();

      switch (response.status) {
        case 200:
          setArticles(response.res);
          setArticlesSearch(response.res);
          break;

        default:
          setToastConfig({
            type: "info",
            title: "We have a problem. ",
            description: `Contact the developer team. Code: ${response.status}`,
          });

          setIsVisibleToast(true);
          setTimeout(() => {
            setIsVisibleToast(false);
            navigate("/dashboard");
          }, 3000);
          break;
      }
    } catch (error) {
      setToastConfig({
        type: "info",
        title: "We have a problem. ",
        description: `Contact the developer team.`,
      });

      setIsVisibleToast(true);
      setTimeout(() => {
        setIsVisibleToast(false);
      }, 3000);
    }
  };

  const imagesCallback = (a: any) => {
    setBlogPost({ ...blogPost, images: a });
  };

  const addTheme = () => {
    if (themeInput.trim() !== "") {
      setBlogPost((prevState) => ({
        ...prevState,
        themes: [...prevState.themes, themeInput.trim()],
      }));
      setThemeInput("");
    }
  };

  const editPostHandler = (id: string) => {
    const postToEdit = articles.filter((article) => article.id === id)[0];

    if (postToEdit) {
      setBlogPost({
        blog_text: postToEdit.blog_text,
        blog_title: postToEdit.blog_title,
        images: postToEdit.images,
        themes: postToEdit.themes,
        video_link: postToEdit.video_link,
        id: postToEdit.id,
      });
    }

    setIsEditing(true);
  };

  const removeThemeHandler = (theme: string) => {
    const blogThemesFilter = blogPost.themes.filter(
      (themeState) => themeState !== theme
    );

    setBlogPost((prevState) => ({
      ...prevState,
      themes: blogThemesFilter,
    }));
  };

  const putPostHandler = async () => {
    if (blogPost.id) {
      setModalContent(<LoadingPage />);
      setOpenUseModal(true);
      const data = new FormData();

      data.append("blog_title", blogPost.blog_title);
      data.append("blog_text", blogPost.blog_text);
      data.append("video_link", blogPost.video_link || "");

      blogPost.images.forEach((image) => {
        if (image instanceof File) {
          data.append("images", image, image.name);
        } else {
          data.append("images", image);
        }
      });

      if (blogPost.themes.length > 0) {
        data.append("themes", JSON.stringify(blogPost.themes));
      }

      try {
        const request = await PutPostById(token, data, blogPost.id);

        switch (request.status) {
          case 200:
            setToastConfig({
              type: "success",
              title: "Success",
              description: "Article save and published with success",
            });

            setIsVisibleToast(true);
            setTimeout(() => {
              setIsVisibleToast(false);
              backPageHandler();
            }, 3000);

            break;
          case 401:
            setToastConfig({
              type: "warning",
              title: "Access Denied",
              description: "You will be redirected",
            });

            setIsVisibleToast(true);
            setTimeout(() => {
              setIsVisibleToast(false);
              navigate("/dashboard");
            }, 3000);

            break;
          default:
            setToastConfig({
              type: "info",
              title: "We have a problem. ",
              description: `Contact the developer team. Code: ${request.status}`,
            });

            setIsVisibleToast(true);
            setTimeout(() => setIsVisibleToast(false), 3000);
            break;
        }
      } catch (error) {
        console.log(error);
        setToastConfig({
          type: "info",
          title: "We have a problem. ",
          description: `Contact the developer team.`,
        });

        setIsVisibleToast(true);
        setTimeout(() => {
          setIsVisibleToast(false);
        }, 3000);
      }
    }
    setModalContent("");
    setOpenUseModal(false);
  };

  const deletePostHandler = async () => {
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);

    if (blogPost.id) {
      try {
        const response = await DeletePostByID(token, blogPost.id);
        switch (response.status) {
          case 200:
            setToastConfig({
              type: "success",
              title: "Success",
              description: "Article deleted with success",
            });

            setIsVisibleToast(true);
            setTimeout(() => {
              setIsVisibleToast(false);
              backPageHandler();
            }, 3000);

            break;
          case 401:
            setToastConfig({
              type: "warning",
              title: "Access Denied",
              description: "You will be redirected",
            });

            setIsVisibleToast(true);
            setTimeout(() => {
              setIsVisibleToast(false);
              navigate("/dashboard");
            }, 3000);
            break;
          default:
            setToastConfig({
              type: "info",
              title: "We have a problem. ",
              description: `Contact the developer team. Code: ${response.status}`,
            });

            setIsVisibleToast(true);
            setTimeout(() => {
              setIsVisibleToast(false);
              navigate("/dashboard");
            }, 3000);
            break;
        }
      } catch (error) {
        console.log(error);
        setToastConfig({
          type: "info",
          title: "We have a problem. ",
          description: `Contact the developer team.`,
        });

        setIsVisibleToast(true);
        setTimeout(() => {
          setIsVisibleToast(false);
          navigate("/dashboard");
        }, 3000);
      }
    }
    setModalContent("");
    setOpenUseModal(false);
  };

  const backPageHandler = () => {
    setBlogPost({
      blog_title: "",
      themes: [],
      blog_text: "",
      images: [],
      video_link: "",
      id: "",
    });
    setIsEditing(false);
    listArticles();
    setOpenUseModal(false);
    setModalContent("");
  };

  const searchArticle = (search: string) => {
    const normalizedSearch = search.trim().toLowerCase();
    const articlesFilter = articles.filter((article) => {
      const { blog_title, themes, created_at } = article;

      return (
        blog_title.toLowerCase().includes(normalizedSearch) ||
        themes.some((theme) =>
          theme.toLowerCase().includes(normalizedSearch)
        ) ||
        created_at.toString().includes(normalizedSearch)
      );
    });

    console.log(articlesFilter);
    setArticlesSearch(articlesFilter);
    setArticleToSearch(search);
  };

  const delePostConfirmModal = () => {
    const confirmDeletePostModalContent = () => {
      return (
        <section className="confirm-delete-post-modal-content">
          <div className="confirm-delete-post-modal-content__text">
            <h1>Attention!</h1>
            <p>Are you sure you want to delete this post?</p>
            <p>This action cannot be undone.</p>
          </div>
          <div className="confirm-delete-post-modal-content__buttons-container margin-top-24">
            <Button
              buttonStyle="primary"
              onClick={() => {
                setOpenUseModal(false);
                setModalContent("");
              }}
            >
              Cancel
            </Button>
            <Button buttonStyle="tertiary" onClick={deletePostHandler}>
              Confirm delete
            </Button>
          </div>
        </section>
      );
    };

    setModalContent(confirmDeletePostModalContent());
    setOpenUseModal(true);
  };

  return (
    <DashboardLayout className="list-blog-edit">
      {isEditing ? (
        <>
          <nav className="list-blog-edit__navbar margin-vert-16">
            <Button
              onClick={backPageHandler}
              buttonStyle="primary"
              className="navbar__back-button button"
            >
              Back Page
            </Button>
            <Button
              onClick={delePostConfirmModal}
              buttonStyle="secondary"
              className="navbar__delete-button button"
            >
              Delete Post
            </Button>
          </nav>
          <section className="list-blog-edit__edit-article">
            <ImageUploader
              buttonText={`add${
                blogPost.images.length >= 1 ? " more images" : " image"
              }`}
              imagesCallback={imagesCallback}
              className="padding-hor-24"
              images={blogPost.images}
            />
            <InputText
              onChangeInput={(e) => setBlogPost({ ...blogPost, video_link: e })}
              value={blogPost.video_link}
              placeholder="Video link"
              label="Add a link from You Tube"
              className="margin-bottom-16"
            />
            <div className="theme-input-container margin-bottom-16">
              <InputText
                onChangeInput={(e) => setThemeInput(e)}
                value={themeInput}
                placeholder="Add a tag for post"
                label="Tags"
                className="theme-input-container__input"
                onKeyDown={(e) => {
                  if (e.key === "Enter") addTheme();
                }}
              />
              <Button
                buttonStyle="secondary"
                onClick={addTheme}
                className="theme-input-container__button"
              >
                Add tag
              </Button>
              <div className="themes-list">
                {blogPost.themes.map((theme, index) => (
                  <span key={index} className="theme-item">
                    {theme}
                    <img
                      src={CloseIcon}
                      alt="x icon"
                      onClick={() => removeThemeHandler(theme)}
                    />
                  </span>
                ))}
              </div>
            </div>
            <InputText
              onChangeInput={(e) => setBlogPost({ ...blogPost, blog_title: e })}
              value={blogPost.blog_title}
              placeholder="Title"
              label="Title"
              className="margin-bottom-16"
            />
            <TextArea
              label="Content"
              onChangeInput={(e) => {
                setBlogPost({ ...blogPost, blog_text: e });
              }}
              value={blogPost.blog_text}
              placeholder="Content"
              rows={10}
              className="margin-bottom-16"
            />
            <Button
              buttonStyle="primary"
              onClick={putPostHandler}
              className="button-publisher"
            >
              Save and Publish
            </Button>
            <Toast
              type="error"
              isVisible={hasError}
              setIsVisible={setHasError}
              title="Error"
              description="Please fill all the fields correctly."
            />
            <Toast
              type={toastConfig.type}
              isVisible={isVisibleToast}
              setIsVisible={setIsVisibleToast}
              title={toastConfig.title}
              description={toastConfig.description}
            />
          </section>
        </>
      ) : (
        <section className="list-blog-edit__list-articles">
          <InputText
            placeholder="Search article"
            onChangeInput={searchArticle}
            value={articleToSearch}
          />
          <BlogArticlesList
            articles={articlesSearch}
            onClick={editPostHandler}
          />
        </section>
      )}
    </DashboardLayout>
  );
};

export default ListBlogEditPage;
